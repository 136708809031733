import { navigate } from "gatsby";

const loginExpiryInMin = 30;

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  return isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {};
  }

export const getFirebaseIdToken = (firebase, callbackFunc, callbackParm) => {
  //console.log('getFirebaseIdToken is called');
  if (!isBrowser() || !firebase || !isLoggedIn()) {
    console.log('not logged in');

    var returnUrl = null;
    if (isBrowser())
      returnUrl = encodeURI(window.location.pathname + window.location.search);
    if (returnUrl)
      navigate(`/app/login?returnUrl=${returnUrl}`);
    else
      navigate('/app/login');
    return;
  }
  //console.log('valid firebase object');

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      let tokenExpiry = window.localStorage.getItem("userIdTokenExpirationTime");
      let foreceRefresh = false;
      if (!tokenExpiry) {
        foreceRefresh = true;
      } else {
        if ((new Date() >= (new Date(tokenExpiry))))
          foreceRefresh = true;
        else {
          //console.log(`firebase token not refreshed.TokenExpiry: ${(new Date(tokenExpiry)).toGMTString()}, now: ${(new Date()).toGMTString()}`);
          callbackFunc(callbackParm);
        }
      }
      if (foreceRefresh) {
        console.log('try to renew firebase token');
        user.getIdTokenResult(true).then(
          function(idTokenResult) {
            window.localStorage.setItem("userIdToken", idTokenResult.token);
            let expiry = new Date(idTokenResult.expirationTime);
            expiry.setMinutes(expiry.getMinutes() - 10);
            document.cookie = `userIdToken=${idTokenResult.token}; expires=${expiry.toUTCString()}; path=/`;
            window.localStorage.setItem("userIdTokenExpirationTime", expiry.toGMTString());
            console.log('firebase token is renewed, expiry: ' + expiry.toGMTString());
            callbackFunc(callbackParm);
          }
        ).catch(function(error) {
          console.log(error+'--'); // Nothing
        });
      }
  
    } else {
      console.log('getFirebaseIdToken: auth().currentUser return null');
      return -1;
    }
  });  

}

export const setUser = (user, next) => {
  if (isBrowser()) {
    let juser = JSON.stringify(user);
    window.localStorage.setItem("user", juser);
    juser = JSON.parse(juser);
    let loginExpiry =new Date();
    loginExpiry.setMinutes(loginExpiry.getMinutes() + loginExpiryInMin);
    window.localStorage.setItem("loginExpiry", loginExpiry.toGMTString());
    if (juser.stsTokenManager) {
      let expiry = new Date(juser.stsTokenManager.expirationTime);
      expiry.setMinutes(expiry.getMinutes() - 10);
      window.localStorage.setItem("userIdToken", juser.stsTokenManager.accessToken);
      document.cookie = `userIdToken=${juser.stsTokenManager.accessToken}; expires=${expiry.toUTCString()}; path=/`;
      window.localStorage.setItem("userIdTokenExpirationTime", expiry.toGMTString());      
      user.getIdTokenResult(false).then(
        function(idTokenResult) {
          window.localStorage.setItem("role", idTokenResult.claims.role?idTokenResult.claims.role:null);
          window.localStorage.setItem("subscribe1Status", idTokenResult.claims.subscribe1Status?idTokenResult.claims.subscribe1Status:null);
          window.localStorage.setItem("subscribe2Status", idTokenResult.claims.subscribe2Status?idTokenResult.claims.subscribe2Status:null);
          window.localStorage.setItem("subscribe3Status", idTokenResult.claims.subscribe3Status?idTokenResult.claims.subscribe3Status:null);
          window.localStorage.setItem("subscribe4Status", idTokenResult.claims.subscribe4Status?idTokenResult.claims.subscribe4Status:null);
          window.localStorage.setItem("validDate", idTokenResult.claims.validDate?idTokenResult.claims.validDate:null);
          if (next)
            next();
        }
      ).catch(function(error) {
        console.log(error); // Nothing
      });

    } else {
      window.localStorage.setItem("userIdToken", null);
      window.localStorage.setItem("userIdTokenExpirationTime", null);            
      window.localStorage.setItem("role", null);
      window.localStorage.setItem("subscribe1Status", null);
      window.localStorage.setItem("subscribe2Status", null);
      window.localStorage.setItem("subscribe3Status", null);
      window.localStorage.setItem("subscribe4Status", null);
      window.localStorage.setItem("validDate", null);
      document.cookie = `userIdToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      
    }


  }
}
export const isLoggedIn = () => {
  const user = getUser()
  if (!!user.email) {
    let loginExpiry =  new Date(window.localStorage.getItem("loginExpiry"));
    let now = new Date();
    if (loginExpiry <= now) {
      setUser({});
      return false;
    }
    loginExpiry = now;
    loginExpiry.setMinutes(loginExpiry.getMinutes() + loginExpiryInMin);
    window.localStorage.setItem("loginExpiry", loginExpiry.toGMTString());
  }
return !!user.email
}

export const logout = (firebase, callbackFunc) => {
  return new Promise(resolve => {
    if (firebase) {
      firebase.auth().signOut().then(function() {
        setUser({});
        resolve();
        if (callbackFunc)
          callbackFunc();
      });
    } 
  })
}

export const checkCanAccessByTag = (tags) => {
  if (tags.length === 1)
    return true;
  for (let i = 0; i < tags.length; i++) {
    if (tags[i].name.startsWith("#role-")) {
      let accessRole = tags[i].name.replace("#role-","");
      return  (isBrowser() && accessRole === window.localStorage.getItem("role"));
    }
  }
  return true;
}

export const hasRole = (roles) => {

  if (isBrowser()) {
    const userRole = window.localStorage.getItem("role");

    if ((userRole === 'admin' || getUser().email === 'rayhwip@gmail.com') && !roles.includes('notadmin'))
      return true;

    var ret = roles.includes(userRole);
    if (!ret && roles.includes('subscribe1'))
      ret = window.localStorage.getItem("subscribe1Status") === "active";
    if (!ret && roles.includes('subscribe2'))
      ret = window.localStorage.getItem("subscribe2Status") === "active";
    if (!ret && roles.includes('subscribe3'))
      ret = window.localStorage.getItem("subscribe3Status") === "active";
    if (!ret && roles.includes('subscribe4'))
      ret = window.localStorage.getItem("subscribe4Status") === "active";
    return ret;


  }
  return false;
}

export function logRouteUsage(route, callbackFunc) {
  //log route usage
  const routeUsageLimit = parseInt(process.env.GATSBY_ROUTE_USAGE_LIMIT_TRIAL_USER);
  let postApiUrl = `${process.env.GATSBY_USER_API_URL}/logRouteUsage?route=${route}`;    
  var request = new XMLHttpRequest();
  request.callObj = this;
  
  request.open('GET', postApiUrl,true);
  request.setRequestHeader('Content-Type','application/json; charset=utf-8');
  request.setRequestHeader('Authorization',`Bearer ${isBrowser()?window.localStorage.getItem("userIdToken"):''}`);
  request.onreadystatechange = function (reposta) {
      
      if (this.readyState === 4) {

        try {
          var ret = JSON.parse(this.responseText);
          var isTrial = !hasRole(['general','advance','subscribe1','subscribe2','subscribe3','subscribe4']);
          if (isTrial && (ret.routeUsage > routeUsageLimit || !ret.routeUsage)) {
            if (callbackFunc)
              callbackFunc();
            else
              navigate('/trialuserwarning');
          }
        } catch {
          if (isTrial && (ret.routeUsage > routeUsageLimit || !ret.routeUsage)) {
            if (callbackFunc)
              callbackFunc();
            else
              navigate('/trialuserwarning');
          }
        }
      }
  };
  request.send();  
}
